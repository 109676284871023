/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andrisam
==============================================================================================*/
@import "sass-lib";

body {  
    color: #333;
    font-size: 13px;
    font-family: "Open Sans", sans-serif;  
    position: relative;
    overflow-x: hidden;
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
img{ border:0; max-width:100%; height:auto;}  
p {
    margin: 1px 0 30px;
    line-height: 26px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }

a {@include transition(all .16s ease-in-out);} 

#loader_box {
    width:100%; 
    height:100%; 
    background:$c_1b1;
    position:fixed;
    left:0;
    top:0;
    z-index:9898989;
    display: none; 
}
#loader_box .preloader {
    position: absolute;
    margin: auto;
    width: 38px;
    height: 40px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background:url('../images/material/logo-loader.png') no-repeat 0 0;
}


/* structure 
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.wrapper, .container { 
    @include clearfix(); @include boxSizing(border-box);  
    width: 1370px; margin: 0 auto; position: relative; 
    &.full {width: 100%; position: static;}  
}

.btn-blue {} 
.btn {
    display: inline-block; position: relative; 
    &.more {
        font-size: 16px; font-weight: bold; text-transform: uppercase; font-family: 'Oswald'; 
        padding: 0 0 19px 0; 
        color: $c_333; 
        &:after {
            position:absolute; width: 100%; height: 4px; content: ""; left: 0; bottom: 0;  
            background: $c_blue; 
        }
        &.white {color: $c_fff; @include textShadow(0px 0px 20px #222);} 
    } 
    &.blue {
        height: 39px; line-height: 39px; background: $c_blue; color: $c_fff; text-transform: uppercase; 
        padding: 0 40px; font-size: 13px; font-weight: 550; 
        /* &:hover {
            background:$c_fff; color: $c_blue; 
        } */
    }
}
.btn-download{
    padding: 0 190px 0 80px; @include boxSizing(border-box); font-size: 16px; text-transform: uppercase; line-height: 50px; border:none; margin-bottom: 70px;
    position: relative; background: #e5e5e5; cursor: pointer; font-family:  "Oswald"; color: #151515; font-weight: 500;
    &:before{ content:''; background: url('../images/material/ic-pdf.png')no-repeat center; position: absolute; left: 20px; top: 0; bottom: 0; width: 16px; height: 22px; margin: auto 0;   }
    &:after{ content: ''; background: url('../images/material/bg-dwnload.png')no-repeat center; position: absolute; right: 0; top: 0; width: 16px; height: 16px;}
}
.fl {float: left;} 
.fr {float: right;}   

.selectLang {
    position: relative; display: inline-block; padding: 0 15px 0 0;
    &:after {
        position:absolute; width: 10px; height: 6px; content: ""; top: 0; right: 0; 
        background: url('../images/material/ico-drop.png') no-repeat 0 0;  
    }
}


.bg-overlay {
    position: fixed; width: 100%; height: 100%; top: 0; left: 0; z-index: 878787; display: none; 
    background: rgba(0,0,0, 0.7);  
}
.side-nav { 
    position: fixed; height: 100%; background: none; top: 0; left:-70%; bottom: 0; z-index: 898989; 
    width: 70%; margin-left: 0; 
    @include boxSizing(border-box); @include clearfix();    
    /*@include transition(all .3s linear);   */
    .fl {
        position: relative; width: 100%; height: 100%; top: 0; left: 0; padding: 0 80px 0 177px;
        background:$c_fff;   
        @include boxSizing(border-box); 
        .inn {
            position: absolute; overflow: auto; padding: 77px 80px 77px 177px; width: 100%; height: 100%; left: 0;
            @include boxSizing(border-box);  
        }
        .as-top {margin:0 0 130px 0;}    
        .as-nav {
            opacity: 1; margin-left: -100px; position: relative;  
            ul {
                position: relative; width: 298px; @include boxSizing(border-box);                  
                li {
                    position: relative; 
                    a {
                        font-size: 31px; font-family: "Oswald"; font-weight: bold; 
                        color: $c_333; display:inline-block; padding: 25px 32px 25px 0; 
                    }
                    &:hover {
                        a {color: $c_blue;} 
                        li {
                            a {color: $c_666;}
                        }
                    }
                    ul {
                        position: absolute; width: 100%; left: 100%; top: 15px; padding: 0 0 0 104px;
                        display: none;
                        &:before { 
                            position:absolute; 
                            width:70px; height: 2px; background: $c_blue; content: ""; left: 0; top: 22px; 
                        }
                        li { 
                            a {
                                font-size: 16px; color: $c_666; font-weight: 550; font-style: italic; 
                                padding: 12px 0;                                   
                            }
                            &:hover {
                                a {color: $c_blue;}  
                            }
                        }
                    }
                    &.dropdown {
                        a {
                            &.parent {
                                position: relative; 
                                &:after {
                                    position:absolute; width: 6px; height: 10px; content: ""; top: 50%; right: 0; 
                                    background: url('../images/material/arr-h-1.png') no-repeat center center; 
                                    @include transform(translateY(-50%)); 
                                }
                                &:hover {
                                    &:after { 
                                        background: url('../images/material/arr-h-2.png') no-repeat center center;
                                    }
                                }
                            }
                        }        
                        &:hover {                            
                            ul {
                                display: block; 
                            }
                        }               
                    }
                }
            }
        }
    }
    .fr {
        float: left;
        width: 80px; height: 100%; position: absolute; top: 0; right:-80px; background: $c_blue;         
    }    
    .burger-nav { 
        position: relative; margin: 48px 0 0 27px; width: 25px; height: 23px; padding: 0; border:0; display: block; 
        background: none; cursor: pointer;    
        span {
            position: absolute; width: 100%; height: 3px; background: $c_fff; left: 0;   
            @include borderRadius(0px); 
            @include transform(rotate(0deg)); 
            @include transition( .25s ease-in-out);

            &:nth-child(1) { 
                top: 0;
                @include transformorigin(left center);
            } 
            &:nth-child(2) {
                top: 10px;
                @include transformorigin(left center);
            }   
            &:nth-child(3) {
                top: 20px;
                @include transformorigin(left center);
            }   
        }
        &.act {                
            span {                 
                @include borderRadius(0px);                 
                &:nth-child(1) {left:3px; top: 0; @include transform(rotate(45deg));}       
                &:nth-child(2) {top: 10px; opacity: 0;}
                &:nth-child(3) {left:3px; top: 17px; @include transform(rotate(-45deg));}     
            }
        }
    }
    .box-lang-mob {display: none;}
    .txt-scroll {  
        position: absolute; left: 50%; bottom: 100px; color: $c_fff; text-transform: uppercase; 
        @include transform(translateX(-50%) rotate(-90deg));   
        span {
            position: relative; padding: 0 0 0 65px;    
            &:before {
                position:absolute; width: 50px; height: 2px; background: $c_fff; content: ""; 
                left: 0; top: 50%; @include transform(translate(0%, -50%));  
            }
        } 
    }
    &.expanded {
        margin-left: 0;       
    }
}

.styledSelect {
    position: relative; display: inline-block; background: $c_fff; border:1px solid $c_eb;   
    min-width: 200px; height: 50px; line-height: 50px;
    @include borderRadius(4px); @include boxSizing(border-box);  
    &:after {
        position:absolute; width: 20px; height: 11px; content: ""; right: 20px; top: 50%; 
        background: url('../images/material/arr-v-1.png') no-repeat center center; 
        @include transform(translateY(-50%)); 
    }
    select {
        border:0; background: none; width: 100%; line-height: 50px; height: 50px; font-size: 16px; 
        padding: 0 48px 0 16px; cursor: pointer; position: relative; z-index: 2;  
        @include boxSizing(border-box); 
    }
}




/* header
----------------------------------------------------------------------------------------------*/
header {
    position: fixed; width: 100%; top: 0; left: 0; padding: 39px 0 0 0; z-index: 8888;
    @include boxSizing(border-box); 
    .logo {
       a {
            width: 226px; height: 40px; display: block;
            img {
                width: 100%;
                &.logo-1 {}
                &.logo-2 {display: none;}
                &.logo-mob {display: none;}
            }
        }
    }
    .fr {
        margin-right: 0;  
    }
    .container {z-index: 25;}
    .bgwhite {
        position:fixed; top:-115px; left:0; width:100%; height: 115px; display: none; background: $c_fff; 
        z-index: 22; border-bottom: 1px solid $c_ea;  
    }    
    .box-search {
        float: left; position: relative; margin: 0 18px 0 0;          
        @include placeholder {  
            color:$c_fff;
        } 
        input {
            background: none; padding: 0; border:0; background:rgba(0,0,0, 0.4);  
            @include boxSizing(border-box); @include borderRadius(4px);   
            &[type="text"]{
                width:200px; height: 40px; line-height: 40px; font-size: 13px; font-style: italic; 
                padding: 0 35px 0 15px; color: $c_fff;
            }  
            &[type="submit"] {
                position: absolute; top: 0; right: 0; width: 40px; height: 40px; text-indent: -999999px; 
                background: url('../images/material/ico-search.png') no-repeat center center;  
                cursor: pointer; 
            }
        }
    }  
    .box-search-mob {
        display: none; 
        float: left; position: relative; margin: 0 18px 0 0;
    }
    .box-lang {
        float: left;
        .selectLang {
            position: relative; display: inline-block; padding: 0 0 0 0; 
            select {
                height: 40px; padding: 0 15px 0 0; border:0; cursor: pointer; color: $c_fff; font-weight: bold;
                background: none; position: relative; z-index: 4; 
                @include boxSizing(border-box);
                option {color: $c_333; padding: 3px;}  
            }
            &:after { 
                position:absolute; width: 10px; height: 100%; content: ""; top: 0; right: 0; 
                background: url('../images/material/ico-drop.png') no-repeat center center;  
            }
        }
    }     
    &.warna {
        .logo {
           a { 
                img {
                    width: 100%;
                    &.logo-1 {display: none;} 
                    &.logo-2 {display: block;}                     
                }
            }
        }
        .box-search {
            input {
                &[type="submit"] {
                    background: url('../images/material/ico-search-fff.png') no-repeat center center; 
                }
            }
        }
        .box-lang {
            .selectLang {
                select {color: $c_blue;}
                &:after {
                    background: url('../images/material/ico-drop-2.png') no-repeat center center; 
                }
            }
        }
    }
}


/* middle
----------------------------------------------------------------------------------------------*/
.middle {
    position: relative; z-index: 4; 
    .banner {
        position: relative; height: 100vh;
        .img {
            position: relative; line-height: 0; z-index: 2; height: 100vh; overflow: hidden; 
            &:before {
                position:absolute; width: 100%; height: 100%; content: ""; left: 0; top: 0; 
                background: url('../images/material/overlay-banner.png') repeat-x 0 0; z-index: 3;
            }  
            &:after {
                position:absolute; width: 100%; height: 100%; content: ""; left: 0; top: 0;  
                background: rgba(0,0,0, 0.4); z-index: 2;  
            }
            img {height: 100vh; width: 100%; object-fit:cover; object-position: center center;}           
        }       
        .caption {
            position: absolute; width: 100%; left:0; top: 50%; padding: 0 255px; z-index: 4;    
            text-align: center; color: $c_fff; text-transform: uppercase;  
            @include boxSizing(border-box); @include transform(translateY(-50%));
            .small {
                font-size: 13px; margin-bottom: 30px;  @include textShadow(1px 1px 10px #222);
            }
            .title {
                font-size: 96px; line-height: 110px; font-weight: bold; font-family: "Oswald"; 
                margin-bottom: 30px; @include textShadow(2px 2px 25px #222); 
            } 
            .des {
                font-size: 16px; line-height: 30px; margin:0 0 35px 0; text-transform: capitalize;   
                padding: 0 25%;
                @include textShadow(1px 1px 10px #222);

            }
        }    
        .slick-initialized {   
            .slick-slide {
                position: relative;   
            }
            .slick-dots {
                position: absolute; width: 100%; left: 0; bottom: 80px; text-align: right; padding: 0 250px; 
                @include boxSizing(border-box); 
                li {
                    display: inline-block; cursor: pointer; width: 60px; height: 60px; border:1px solid transparent; 
                    position: relative; 
                    @include boxSizing(border-box); @include borderRadius(50%);
                    button {
                        width: 10px; height: 10px; background: $c_fff; font-size: 13px; position: absolute;
                        top:0; right: 0; bottom: 0; left: 0; margin: auto; border:0; padding: 0; 
                        text-indent: -999999px; cursor: pointer;
                        @include borderRadius(50%); @include boxShadow(1px 1px 20px #333);  
                        &:before { 
                            content:"0"; 
                        } 
                    }
                    &.slick-active {
                        border:1px solid $c_fff; 
                        button {
                            width: 30px; height: 30px; text-indent: 0;    
                        }
                    }
                }
            }
        }    
        &.v2 {
            height: auto;
            .img {
                height: auto;
                img {height: auto;} 
            } 
            .caption {
                top: 58%;
                .title {font-size: 62px; line-height: 70px;} 
            }
        }        
    }
    
    .colbox {         
        h2 {
            font-size: 14px; text-transform: uppercase; margin-bottom: 40px; 
            i {
                display: inline-block; font-size: 16px; font-weight: bold; font-style: normal;
                font-family: "Oswald"; 
            }     
            span { 
                display: inline-block; position: relative; padding: 0 0 0 75px;
                &:before {
                    position:absolute; width: 50px; height: 2px; content: ""; left: 15px; top: 50%;  
                    background: $c_blue;
                    @include transform(translateY(-50%));    
                } 
            }
            &.no {
                i {
                    padding-right: 60px; position: relative; 
                    &:after {
                        position:absolute; width: 50px; height: 2px; content: ""; right: 0; top: 50%;  
                        background: $c_blue;
                        @include transform(translateY(-50%));    
                    }
                }
            }
        }
        h3 {
            font-family: "Oswald"; font-size:49px; line-height: 60px; font-weight: bold; text-transform: uppercase;  
            margin-bottom: 40px; 
        }
        h4 {
            font-size: 25px; font-weight: bold; text-transform: uppercase; font-family: "Oswald"; 
            margin:0 0 25px 0;
            &.blue {
                color: $c_blue; text-transform: capitalize; font-family: "Open Sans";
            }
        }
        .des { 
            font-size:16px; line-height: 26px; color: $c_666; padding: 0 0 0 0; margin: 0 0 35px 0;   
            &.v2{
                height: 50px; overflow: hidden;
            }  
        }
        .authorized {
            margin-bottom: 45px; 
            p {color: $c_999; line-height: 16px; text-transform: uppercase; margin: 0 0 13px 0;}  
        }
        &.v2 {
            h2 {
                span {
                    padding: 0 0 0 65px; 
                    &:before {left:0;}
                }
            }
        }
    }

    .home-about { 
        .container {padding: 150px 0;} 
        .col {
            float:left; width: 50%; @include boxSizing(border-box);  
            &.img {padding:0 60px 0 0; line-height: 0;} 
            &.text {padding:30px 0 0 60px;} 
        }
    }
    .box-tab {
        .nav-tab {
            margin-bottom: 50px;  
            ul {
                li {
                    display: inline-block; margin-right: -4px; 
                    a {
                        display: block; height: 39px; line-height: 39px; padding: 0 13px; text-align: center; 
                        font-size: 13px; text-transform: uppercase; color: $c_161;  
                        @include boxSizing(border-box);                        
                    }
                    &:hover,
                    &.active {
                        a {background:$c_blue; color: $c_fff;}
                    }
                }
            }
        }
        .tab-container { 
            height: auto; max-height: 370px; position: relative; overflow: auto; 
        }
    }
    .box-contact { 
        position: relative; height: 873px;   
        .cl { 
            float:left; width: 48%; @include boxSizing(border-box);    
            &.text {
                padding: 50px 200px 0 0; font-size: 16px; line-height: 26px;   
                p {
                    font-size: 16px; line-height: 26px;  
                }
                a {color: $c_333;} 
                .list_cabang {
                    .col {}                    
                }
            }
        }
        .map {
            position: absolute; width: 52%; height: 100%; top: 0; right: 0; line-height: 0; 
            h4 {margin-bottom: 5px; line-height: 24px;}    
            img {height: 50px;}    
            .info_content {
                width: 250px; 
                p {margin: 0; line-height: 16px;} 
            }
            .content_map {
                @include clearfix(); width: 250px;                                
                .siteContent {
                    float: left; width: 100%; padding: 0 0 0 0; 
                    @include boxSizing(border-box);
                    h4 {font-size: 20px; line-height: 22px;} 
                    p {line-height: 16px; margin: 0;} 
                }
            }                 
        }
        #idMap-1 {}
        #idMap-2 {display: none;}
        #idMap-3 {display: none;}
        #idMap-4 {display: none;}
        &.page-contact {
            height: auto; position: relative;            
            .container {padding: 80px 0; width: 1370px;} 
            h3 {font-size: 33px; line-height: 40px;}  
            h4 {color: $c_333;}  
            .row {
                @include clearfix(); position: relative;    
                 &:before {
                    position:absolute; width: 1px; height: 100%; content: ""; background:$c_efe; 
                    left: 40%; top: 0; display: block;  
                    @include transform(translateX(-50%));  
                }
            }
            .nav-tab {
                ul {
                    margin: 0;
                    li {margin-bottom: 0;}      
                } 
            }
            .col {
                &.text {padding: 0;} 
                &.form {
                    padding: 0 0 0 64px; 
                }
            }
            .map {                
                position: relative; width: 100%; height: 600px;    
                h4 {font-size: 20px; text-transform: capitalize; font-weight: normal;}
            }
        }

    }

    .form-std {
        color: $c_333; 
        .col {width: 100%; border:none; margin-bottom: 35px;}  
        label {
            display: block; font-size: 16px; font-weight: bold; text-transform: uppercase; 
            margin-bottom: 15px; 
        }
        @include placeholder {color: $c_909;}  
        input {
            height: 42px; line-height: 42px; @include boxSizing(border-box); padding: 0; border:none;
            border-bottom: 1px solid $c_b2b; width: 100%; 
            font-size: 16px; font-style: italic; color: $c_909;  
        } 
        textarea {
            min-height:110px; line-height: 18px; @include boxSizing(border-box); padding: 5px 0; border:none;
            border-bottom: 1px solid $c_b2b; width: 100%; font-size: 16px; font-style: italic; color: $c_909;
            max-width: 100%; resize: none;  
        } 
        .btn {
            display: inline-block; width: auto; height: 39px; line-height: 39px; padding: 0 35px; font-size: 13px; font-weight: 500;
            background: $c_blue; color: $c_fff; border:none; cursor: pointer; text-transform: uppercase; 
            letter-spacing: 2px; @include transition(all .15s ease-in-out); font-style: normal;   
            &:hover {
                background:$c_blue_2; 
            }
        }
    }

    .col-corp {
        border-top:1px solid $c_ea; 
        .container {padding: 0 80px;} 
        .fl {            
            width: 40%; border-right: 1px solid $c_b5b; @include boxSizing(border-box);
            .col {
                float:left; width: 50%; height: 180px; padding: 30px 0 30px 0; text-align: center; 
                border-left: 1px solid $c_b5b; 
                @include boxSizing(border-box); 
                h4 {font-size: 13px; text-transform: uppercase; color: $c_333; margin-bottom: 25px;} 
                .img {
                    height: 75px; line-height: 0; position: relative;
                    img {
                        position: absolute; margin: auto; top: 0; right: 0; bottom:0; left: 0;
                        filter: url(grayscale.svg#greyscale);
                        filter: gray;
                        -webkit-filter: grayscale(100%);
                        -moz-filter: grayscale(100%);
                        -ms-filter: grayscale(100%);
                        -webkit-filter: grayscale(100%);
                        -o-filter: grayscale(100%);  
                    }
                }   
                &:hover {
                    .img {
                        img {
                            filter: none; 
                            -webkit-filter: grayscale(0%);
                            -moz-filter: grayscale(0%);
                            -ms-filter: grayscale(0%);
                            -webkit-filter: grayscale(0%);
                            -o-filter: grayscale(0%);

                        }
                    }
                }                
            } 
        }
        .fr {
            width: 60%; @include boxSizing(border-box);
            .col {
                float: left; width: 33.333333%; position: relative; height: 90px; 
                border-right: 1px solid $c_b5b; border-bottom: 1px solid $c_b5b;
                @include boxSizing(border-box);
                .img {
                    position: relative; height: 100%;
                    img {
                        position: absolute; margin: auto; top: 0; right: 0; bottom:0; left: 0;
                        filter: url(grayscale.svg#greyscale);
                        filter: gray;
                        -webkit-filter: grayscale(100%);
                        -moz-filter: grayscale(100%);
                        -ms-filter: grayscale(100%);
                        -webkit-filter: grayscale(100%);
                        -o-filter: grayscale(100%);
                    }
                }
                &:hover {
                    .img {
                        img {
                            filter: none; 
                            -webkit-filter: grayscale(0%);
                            -moz-filter: grayscale(0%);
                            -ms-filter: grayscale(0%);
                            -webkit-filter: grayscale(0%);
                            -o-filter: grayscale(0%);

                        }
                    }
                } 
            }   
        }
    }

    .list-row { 
        background: $c_f6f; 
        .container {padding: 150px 0;}  
        .col {
            float:left; position: relative; @include boxSizing(border-box);   
            &.img {
                padding:0 0 0 0; width: 53.17%; line-height: 0; 
                &:before {
                    position:absolute; width: 100%; height: 100%; content: ""; top: 0; left: 0;
                    /*background: rgba(0,0,0, 0.1);   */
                    background: url('../images/material/overlay-csr-2.png') repeat-x 0 0;  
                }
            } 
            &.text {width: 46.83%; padding:30px 0 0 60px;}            
        }
        &.v2 {
            .container {width: 1170px;}   
            .row {
                @include clearfix(); margin-bottom: 50px; border-bottom: 1px solid $c_ddd;
                padding: 0 0 50px 0; 
            }
        }
        &.v3 {
            .col {
                &.img {
                    &:before {display:none;}  
                    img {margin-top: -65px;} 
                }
                &.text {
                    padding-top: 50px;
                    h3 {margin-bottom: 10px;}
                    .position {
                        font-family: "Oswald"; font-size: 25px; line-height: 30px; font-weight: bold; text-transform: uppercase;
                        margin-bottom: 25px; color: $c_161;     
                    }
                } 
            }
        }
        &.business {
            background: none; 
            .row {
                @include clearfix(); 
                margin: 0 0 70px 0; 
                .des {padding-right: 0;}
                .col {
                    &.img {width: 48.82%;} 
                    &.text {padding-top: 55px; padding-right: 60px; width: 51.1%;}       
                }
                &:nth-child(even) {
                    .col {
                        &.img {float: right;} 
                        &.text {float: left;}   
                    }
                } 
                &:last-child {margin-bottom: 0;}   
            }            
        }
    }

    .midcontent { 
        font-size: 16px; color: $c_666; 
        .container {padding: 110px 0; width: 1170px;}  
        &.v3 {
            .container {width: 1370px; padding: 70px 0 110px 0;}   
        }  
        &.v4 {
            .container {width: 1270px;}  
        }  
        .img {line-height: 0; position: relative;}  
        h3 {color: $c_161;}  
        h5 {font-size: 23px; line-height: 30px; color: $c_blue; margin: 0 0 30px 0;} 
        h6 {font-size: 19px; font-weight: bold; font-family: "Oswald"; margin: 0 0 10px 0;}  
        ul {
            margin-left: 30px; padding: 0 0 10px 0;
            li {
                list-style: disc; line-height: 22px; margin-bottom: 5px; 
            }
        }
        ol {
            margin-left: 30px; padding: 0 0 10px 0;
            li {
                list-style:decimal; line-height: 22px; margin-bottom: 5px; padding: 0 0 0 5px;   
            }
        }
        .inner {
            padding: 95px 95px 0 95px; 
            &.v2 {padding-bottom: 30px;}  
            &.v3 {padding-bottom:190px;}  
            &.nopadtop{ padding-top:30px;  }
        }
        .box-img {      
            padding: 40px 0 0 0; position: relative;
            .img {
                line-height: 0;
                &:before {
                    position:absolute; width: 100%; height: 100%; top: 0; left: 0; content: ""; 
                    background: rgba(0,0,0, 0.5);  
                }
            } 
            &.play {      
                height: 535px; @include boxSizing(border-box);
                .img {  
                    cursor: pointer; position: relative; z-index: 2; top: 0; left: 0; height: 100%;
                    img {
                        height: 100%; width: 100%; object-fit: cover; 
                    }
                    &:after {
                        position: absolute; width: 128px; height: 128px; content: ""; left: 50%; top: 50%; 
                        background: url('../images/material/iplay.png') no-repeat center center; 
                        @include transform(translate(-50%,-50%)); 
                    } 
                }
                .video {
                    width: 100%; height: 100%; position: absolute; top: 0; left: 0; padding: 40px 0 0 0;  
                    @include boxSizing(border-box);
                    iframe {width: 100% !important; height: 100% !important;}   
                }
            }
            &.img-slider { 
                .slick-dots {
                    margin: 0; text-align: center; position: absolute; width: 100%; left: 0; bottom: 20px;
                    li {
                        list-style: none; display: inline-block; margin: 0 4px;    
                        button {
                            width: 10px; height: 10px; text-indent: -999999px; padding: 0; border:0; 
                            background: $c_ccc; cursor: pointer; 
                            @include borderRadius(50%); 
                        }
                        &.slick-active {
                            button {
                                background: $c_fff;
                            }
                        }
                    }
                }
            }
        }
        .box-vimis {
            padding: 140px 0 0 0; 
            .row {
                @include clearfix(); border-bottom: 1px solid $c_d4d; padding: 0 0 60px 0; margin: 0 0 60px 0;  
                .col {
                    float: left; 
                    @include boxSizing(border-box);
                    &.vtitle {width: 34.2%; color: $c_161;}    
                    &.vdes {width: 65.8%;}     
                }
                &:last-child {padding-bottom: 0; margin-bottom: 0; border-bottom: 0;}  
            }
        }
        
        .col-milestone {
            position: relative; 
            
            .row {
                height: 752px; position: relative; 
                &:before {
                    position:absolute; width: 100%; height: 10px; background: $c_161; content: ""; left: 0; 
                    top: 50%; @include transform(translateY(-50%)); 
                }
                .col {
                    position: relative; width: 197px; height: 345px; 
                    @include transition(all .15s ease-in-out); 
                    &:before {
                        position:absolute; width: 1px; height: 82%; background: $c_ccc; top: 0; left: 0;
                        content: ""; 
                    }
                    .txt {
                        position: absolute; width: 100%; left: 0; bottom: -29px;                          
                        .mile-ico {
                            margin: 0 0 10px 20px; width: 50px; height: 50px; background-size: 100% 100% !important; 
                            text-indent: -999999px; background: $c_434; 
                            @include borderRadius(50%);     
                            span {
                                display: block; width: 50px; height: 50px; position: relative; 
                                @include transition(all .15s ease-in-out);
                                img {
                                    position: absolute; margin:auto; top:0; left: 0; right: 0; bottom: 0; 
                                }
                            } 
                            /* &.cate-1 {background:url('../images/content/mile-ico-1.png') no-repeat center center;} 
                             &.cate-2 {background:url('../images/content/mile-ico-2.png') no-repeat center center;} 
                             &.cate-3 {background:url('../images/content/mile-ico-3.png') no-repeat center center;} 
                             &.cate-4 {background:url('../images/content/mile-ico-4.png') no-repeat center center;} 
                             &.cate-5 {background:url('../images/content/mile-ico-5.png') no-repeat center center;} 
                             &.cate-6 {background:url('../images/content/mile-ico-5.png') no-repeat center center;} */ 
                        }
                        .mile-des {
                            margin: 0 0 10px 20px; height: 210px; font-size: 14px;
                            .mCSB_inside > .mCSB_container {
                                margin-right: 18px; 
                            }
                        }     
                        .mile-year {
                            font-size:57px; font-weight: bold; font-family: 'Oswald'; color: $c_333; 
                            position: relative; @include transition(all .15s ease-in-out); 
                            padding: 25px 0 0 0;
                            .start {
                                font-size: 24px; display: block; padding: 0 0 5px 0;
                                position: absolute; left: 0; top: 0;
                            } 
                        }
                    }
                    /* &:first-child {
                        .mile-year {color: $c_blue;} 
                    } */
                    &:nth-child(even) {
                        &:before {
                            top:100%; margin-top: 125px;
                        }
                        .txt {
                            top: 109%; margin-bottom: 0;
                            flex-flow: column;
                            display: -webkit-flex;
                            display: flex;
                            -webkit-flex-flow: column; 
                            &:before {top:75px;} 
                            .mile-ico {
                                display: block; order: 2; -webkit-order:2; margin-top: 20px;
                            }
                            .mile-des {
                                display: block; order: 3; -webkit-order:3;
                            }
                            .mile-year {
                                display: block; order: 1; -webkit-order:1; margin:0 0 0 0;   
                                .start {
                                    position: absolute; bottom: -25px; left: 0; width: 100%;
                                    padding: 10px 0 0 0;
                                } 
                            }
                        }
                    }
                    &:hover { 
                        .mile-year {color: $c_blue;}   
                        .mile-ico {    
                            background: $c_blue;                        
                            /* &.cate-1 {background:url('../images/content/mile-ico-1-act.png') no-repeat center center;} 
                             &.cate-2 {background:url('../images/content/mile-ico-2-act.png') no-repeat center center;} 
                             &.cate-3 {background:url('../images/content/mile-ico-3-act.png') no-repeat center center;} 
                             &.cate-4 {background:url('../images/content/mile-ico-4-act.png') no-repeat center center;} 
                             &.cate-5 {background:url('../images/content/mile-ico-5-act.png') no-repeat center center;} 
                             &.cate-6 {background:url('../images/content/mile-ico-5-act.png') no-repeat center center;} */ 
                        }
                    }
                }
                .slick-arrow {
                    position: absolute; width: 28px; height: 22px; content: ""; top: 46%; z-index: 155; border:none;  
                    opacity: 0.4; cursor: pointer; text-indent: -999999px;   
                    @include transform(translateY(-50%));  
                    &.slick-prev {
                        left: 0; background: url('../images/material/btn-arrow-prev-black.png') no-repeat center 0;
                    }
                    &.slick-next {right: 0; background: url('../images/material/btn-arrow-next-black.png') no-repeat center 0;}
                    &.slick-disabled {cursor: default; opacity: 0.2;}
                }
                .slick-list {margin: 0 45px; height: 100%;} 
            }

            .custom-arrow {
                position: absolute; width: 100%; height: 25px; z-index: 155; top: 50%; left: 0; margin-top: -30px;
                @include transform(translateY(-50%)); 
            } 
            .c-arrow {
                position: absolute; width: 28px; height: 22px; content: ""; top: 46%; z-index: 155; border:none;  
                opacity: 0.4; cursor: pointer; text-indent: -999999px;  
                @include transform(translateY(-50%));  
                &.prev {
                    left: 0; background: url('../images/material/btn-arrow-prev-black.png') no-repeat center 0;
                }
                &.next {right: 0; background: url('../images/material/btn-arrow-next-black.png') no-repeat center 0;}
                &.slick-disabled {cursor: default; opacity: 0.2;} 
            } 
        }

        &.career-detail {  
            .container {padding: 85px 0;}  
            ul {
                margin: 0 0 55px 0;
                li {
                    list-style:none; position: relative; padding: 0 0 0 20px;   
                    &:before {
                        position:absolute; content: "."; color: $c_blue; font-weight: bold; left: 0; top: -8px;
                        font-size: 30px;
                    }
                }
            }
            table {
                margin: 0 0 80px 0; width: 50%;
                tr {
                    td {
                        padding: 5px 5px; color: $c_333;  
                    }
                }
            }
            a {
                color: $c_blue; font-style: italic; font-weight: bold;  
            }
        }
    }

    .list-manag {      
        .container {padding: 150px 25px 120px 25px;}        
        .col {
            float: left; width: 50%; padding: 0 35px 0 0;   
            @include boxSizing(border-box);              
            &:nth-child(2n+2) {padding-left: 35px; padding-right: 0;}           
            .img {
                line-height: 0; margin:0 35px 40px 0;     
            }
            .name {
                font-family: "Oswald"; font-size: 40px; line-height: 60px; font-weight: bold; text-transform: uppercase;
                margin-bottom: 12px; color: $c_161; 
            }
            .position {
                font-family: "Oswald"; font-size: 25px; line-height: 30px; font-weight: bold; text-transform: uppercase;
                margin-bottom: 40px; color: $c_161;     
            }
            .descrip {
                p {}
            }

        }         
    }

    .col-achievement {
        @include clearfix(); 
        .col {
            float: left; width: 23.08%; height: 320px; margin: 0 2.5% 60px 0; position: relative;              
            background:$c_e1; cursor: pointer; 
            @include boxSizing(border-box);     
            &:nth-child(4n+4) {margin-right: 0;}     
            &:before {
                position: absolute; width: 100%; height: 100%; content: ""; top: 10px; left: 10px; 
                border:2px solid $c_aea; 
            }
            .inn {
                position: relative; z-index: 3; background: none; padding: 57px 0 30px 0;  
                margin: 0 30px;
                height: 100%; color: $c_161;
                @include boxSizing(border-box);
                .year {
                    position: relative; padding: 0 0 0 52px; font-size: 16px; margin: 0 0 25px 0; 
                    &:before {
                        position:absolute; width: 40px; height: 2px; background: $c_blue; content: ""; 
                        left: 0; top: 50%; @include transform(translateY(-50%)); 
                    }
                }
                .title {
                    font-size: 25px; font-weight: bold; font-family: "Oswald"; text-transform: uppercase; 
                }
                .btn {
                    position: absolute; left: 0; bottom: 30px; 
                }
            }
            &:hover {
                &:before { 
                    border-color:$c_blue;  
                }
            }
        }
    }

    .filtering { 
        @include clearfix(); margin-bottom: 45px; padding: 0 100px;  
        .fl {
            label {
                display: inline-block; margin: 0 22px 0 0; font-size: 16px; color: $c_blue; text-transform: uppercase;
                font-weight: bold; 
            } 
        }
        .fr {
            .fsearch {
                position: relative; position: relative; min-width: 365px;
                @include placeholder {color: $c_999;}
                input {
                    &[type="text"] {
                        width: 100%; height: 50px; line-height: 50px; padding: 0 42px 0 13px;
                        border:1px solid $c_ea; font-size: 16px; color: $c_999; font-style: italic; 
                        @include boxSizing(border-box); @include borderRadius(4px);
                    }
                    &[type="submit"] {
                        position: absolute; top: 0; right: 0; width: 40px; height: 100%; padding: 0; border:none;
                        background: url('../images/material/ico-search-2.png') no-repeat center center;
                        cursor: pointer;  
                    }
                }
            }
        }
    }
    .list-csr {
        @include clearfix(); 
        .col {
            float: left; width: 46.72%; margin: 0 6.5% 6% 0; position: relative; overflow: hidden;   
            &:nth-child(2n+2) {margin-right: 0;} 
            @include boxSizing(border-box); 
            .img {
                line-height: 0; position: relative; z-index: 1;  
                &:before {
                    position:absolute; width: 100%; height: 100%; content: ""; left: 0; top: 0;
                    background: url('../images/material/overlay-csr.png') repeat-x 0 0; z-index: 3;
                }
                img { 
                    position: relative; z-index: 1; 
                    @include transition(all .18s ease-in-out);   
                }
            }
            .caption {
                position: absolute; width: 100%; bottom: 0; padding: 0 35px 40px 35px; 
                z-index: 2;
                @include boxSizing(border-box);
                h4 {color: $c_fff; font-size: 33px; font-weight: bold; font-family: "Oswald";}  
            }
            .date {
                position: absolute; top: 0; right: 20px; padding: 25px 10px; background: $c_blue;  
                color: $c_fff; font-family: "Oswald"; font-size: 29px; z-index: 3; text-align: center; 
                font-weight: bold; 
                span {
                    display: block; font-size: 23px; line-height: 28px; 
                } 
            }
            &:hover {
                .img {
                    img {@include transform(scale(1.1));} 
                }
            }
        }
    }
    
    .our-product {        
        padding:20px 100px 0 100px; margin-top: 20px; 
        .row {
            @include clearfix(); padding: 80px 0 50px 0; border-bottom: 1px solid $c_d8d; margin: 0 0 0 0; 
            h3 {
                font-size: 40px;font-weight: bold; color: $c_161; text-transform: uppercase; position: relative;
                font-family: "Oswald"; margin: 0 0 40px 0; padding: 0 0 0 80px;
                &:before {
                    position:absolute; width: 50px; height: 2px; background: $c_blue; content: ""; left: 0; top: 50%;
                    @include transform(translateY(-50%));  
                }
            } 
            .box {
                @include clearfix(); 
                 .galSlider {
                    margin:0 0 10px 0;
                    .slide {
                        @include clearfix(); 
                        .gallery {line-height: 0;}
                    }  
                }
                .col {
                    float: left; @include boxSizing(border-box);   
                    &.gallery {
                        width: 53%;           
                    } 
                    &.text {
                        width: 47%; padding: 0 0 0 70px; margin: 40px 0 0 0; 
                        position: relative; 
                        height: 325px; 
                        /*min-height: 325px; max-height: 325px; */
                        /*overflow-y: auto;    */
                        .title {
                            font-size: 19px; color: $c_666; font-weight: bold; font-family: 'Oswald'; 
                            margin: 0 0 15px 0;
                        } 
                        p {
                            font-size: 16px; line-height: 26px; color: $c_666; 
                        }
                        ul {
                            @include clearfix(); 
                            float: none; display: block; margin-bottom: 30px; 
                        }
                    } 
                } 
                 .thumbSlider {
                    float: left; width: 53%; 
                    .slick-list {
                        overflow: hidden; position: relative;
                        .slick-track {margin: 0;}
                    } 
                    .thumb {
                        float: left; cursor: pointer; line-height: 0; width: 80px; height: 80px;
                        margin: 0 10px 0 0; position: relative; overflow: hidden;
                        img {
                            max-width: 100%; width: 100%; height: 100%; position: absolute;
                            margin: auto; left: 0; top: 0; bottom: 0; right: 0;   
                            object-fit: cover; object-position: center center; 
                        }
                        &:nth-child(7n+7) {} 
                        &:last-child {margin-right: 0;}
                    }
                    .slick-arrow { 
                        position: absolute; width: 28px; height: 28px; content: ""; padding: 0; border:0; top: 50%;
                        text-indent:-999999px; cursor: pointer; z-index: 155; opacity: 0.8; 
                        background:rgba(0,0,0, 0.8);
                        @include transform(translateY(-50%)); 
                        &:before {
                            position:absolute; width: 20px; height: 14px; content: ""; top: 50%; left: 50%; 
                            @include transform(translate(-50%,-50%));  
                        }
                        &.slick-next {
                            right: 0;
                            &:before {
                                background:url('../images/material/btn-arrow-next.png') no-repeat center center;
                                background-size: 100%; 
                            }
                        }
                        &.slick-prev {
                            left:0;
                            &:before {
                                background:url('../images/material/btn-arrow-prev.png') no-repeat center center;
                                background-size: 100%;
                            }
                        }
                        &.slick-disabled {cursor: default; opacity: 0; width: 0; height: 0;}
                    }
                }
            } 
            &:first-child {border-top: 1px solid $c_d8d;}
            &:last-child {margin-bottom: 0;}
        }
    }   

    .list-career { 
        padding: 64px 0 0 0; margin: 25px 0 0 0; border-top: 1px solid $c_c9c; 
        h2 {margin-bottom: 18px;}    
        .row {
            position: relative; @include clearfix();   
            .col {
                float: left; width: 23.04%; margin: 0 2.6% 2.6% 0; min-height: 320px;
                background: $c_d7d; padding: 25px; position: relative; @include transition(.12s ease-in-out); 
                @include boxSizing(border-box); 
                &:nth-child(4n+4) {margin-right: 0;} 
                .lokasi {
                    font-size: 16px; color: $c_333; text-transform: uppercase; margin: 0 0 18px 0; 
                    position: relative; padding: 0 0 0 40px; 
                    &:before {
                        position:absolute; width: 30px; height: 2px; background: $c_blue; content: ""; 
                        left: 0; top: 50%; @include transform(translateY(-50%)); 
                    }
                }
                .title {
                    font-size: 25px; font-weight: bold; font-family: "Oswald"; text-transform: uppercase; 
                    color: $c_333; line-height: 35px;
                }
                .btn {
                    position: absolute; left: 25px; bottom: 25px; 
                }
                &:hover {
                    background: $c_blue; 
                    .lokasi {
                        color: $c_fff;
                        &:before {background:$c_fff;}  
                    }
                    .title {color: $c_fff;} 
                    .btn {background:$c_fff; color: $c_blue;} 
                }
            }
        }
    }
    
    
    .paging {
        text-align: right;  
        ul {
            li {
                display: inline-block; margin-right: -2px; 
                a {
                    display: inline-block; font-size: 14px; color: $c_666; font-weight: bold;  
                    padding: 0 6px; height: 24px; line-height: 24px; 
                    &:hover, &.active {
                        color:$c_fff; background: $c_blue;  
                    }
                }
                &.prev,
                &.next {
                    a {
                        &:hover, &.active {
                            color:$c_666; background: none; 
                        }
                    }
                }
                &.disabled {
                    a {
                        color: $c_d7d;
                        &:hover, &.active {
                            color:$c_d7d;
                        }
                    }
                }
            }
        }
        &.center {text-align: center;} 
    }

    .search-result {
        @include clearfix();
        padding:0 0 15px 0; border-bottom: 1px solid $c_d9d; margin:0 0 30px 0;  
        font-size: 16px;
    }
    .list-result {
        padding: 0 0 25px 0;
        .row {
            border-bottom: 1px solid $c_e6e; padding: 30px 0;  
            &:first-child {padding-top:0;} 
            a { 
                display: block; @include clearfix(); color: $c_333; 
                .col {
                    float: left; @include boxSizing(border-box); 
                    &.thumb {
                        width: 19.55%; line-height: 0; 
                        img {width: 100%; object-fit: cover;}
                    }
                    &.text {
                        width: 100%; padding: 0;  
                        span {display: block;} 
                        .title {
                            font-size: 18px; color: $c_333; font-weight: bold; margin-bottom: 10px; 
                        }
                        .des {font-size: 13px; line-height: 20px; margin-bottom: 20px;}
                        .date {
                            font-size: 13px;
                            span {
                                display: inline-block;
                                &.category {color: $c_blue;} 
                            }
                        }
                    } 
                }
                &:hover {
                    opacity: 0.8;
                }
            }
            &.thumb {
                a {                  
                    .col { 
                        &.text {width: 80.45%; padding: 0 0 0 15px;}
                    }
                }
            }
        }
    }

}


body.lock {overflow: hidden;}  
.comingsoon {
    min-height: 100vh; height: 100vh; height: 100%; background: url('../images/material/bg-comingsoon.jpg') no-repeat center center;
    background-size: cover; text-align: center; color: $c_fff;    
    .container {
        padding: 0 47px;  width: 1170px;   
        @include boxSizing(border-box); 
    }
    .cm-top {padding: 32px 0 0 0;}
    .cm-mid {
        padding: 200px 0 285px 0;  
        .cate {
            position: relative; padding: 0 0 0 74px; display: inline-block; font-size: 13px;
            text-transform: uppercase; margin-bottom: 40px; 
            &:before {
                position:absolute; width: 50px; height: 2px; content: ""; top: 50%; left: 0; 
                background: $c_fff;                 
                @include transform(translateY(-50%)); 
            }
        }
        .title {
            font-size: 40px; line-height: 60px; text-transform: uppercase; font-weight: bold; 
            margin-bottom: 30px; font-family: "Oswald";  
            @include textShadow(0px 2px 7px #000);
        }
        p {
            font-size:19px; line-height: 26px; padding: 0 225px; margin-bottom: 0; 
        }
    }

    .cm-bot {
        font-size: 14px; 
        p {
            margin: 0 0 20px 0; line-height: 20px; @include textShadow(0px 2px 5px #000);
        }
     }
}

.content-404 {
    min-height: 300px; margin: 0 50px 0px 50px; text-align: center;  
    .c4-title {font-size: 100px; color: $c_000; margin: 0 0 25px 0;} 
    .subtitle {font-size: 22px; margin: 0 0 10px 0;}   
}



/* footer
----------------------------------------------------------------------------------------------*/
footer {
    padding: 34px 0; background: $c_1b1 url('../images/material/bg-foot.png') no-repeat right 0;  
    .logo-foot {} 
    .nav-foot {
        margin: 0 0 15px 0;
        ul {
            li {
                display: inline-block; 
                a {
                    display: block; font-size: 14px; text-transform: uppercase; color: $c_999; 
                    padding: 0 5px; 
                    &:hover {
                        color:$c_a5a; 
                    }
                }
            }
        }
    }
    .copyright {
        font-size: 13px; color: $c_999; text-align: right; margin: 0; line-height: 16px;
        a {
            color: $c_999;
            &:hover {text-decoration:underline;}  
        }
    }
}

.mCSB_scrollTools {width: 10px;}  
.mCSB_scrollTools .mCSB_draggerContainer {width: 100%;} 
.mCSB_scrollTools .mCSB_draggerRail { 
    width: 6px; 
    background:$c_d9d; 
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 6px;
    background: $c_666; 
}
.mCSB_outside + .mCSB_scrollTools {right:-50px;}


header, footer, .middle {padding-left: 80px;} 

body.lock {overflow-y: hidden;}  
.wrap_popup {
    background: rgba(0,0,0, 0.8);      
    position: fixed;
    width: 100%;
    height: 100%;    
    z-index: 999999;
    top: 0;
    left: 0;
    overflow-y: auto;
    .pop_inner {
        display: table;
        height: 100%;
        width: 100%;
        position: absolute;
        .middle_pop {
            display: table-cell;
            vertical-align: middle;            
        }
    }   
    .overlay_bg {
        position: absolute; width: 100%; height: 100%; 
        z-index: 78787; left: 0; top: 0; margin: 0;
    }
}
#boxPop { 
    min-width: 1030px;    
    max-width: 1030px; 
    min-height: 560px;
    margin: 100px auto 50px auto; 
    position: relative;
    z-index: 878787;     
    
    .close_pop {
        position: absolute;
        width: 40px;
        height: 40px;
        background: url(../images/material/pop_close.png) no-repeat 0 0;  
        right: -40px;
        top: 0px;  
        cursor: pointer;        
    }   
    .content_pop {        
        background: none; min-height: 500px; position: relative;
        .sc_content {  
            z-index: 22;     
            min-height: 500px; background: $c_fff;  
            .inner {
                padding: 25px; @include clearfix(); 
            }
            .detImg {
                position: relative; width: 35.72%; float: left; line-height: 0; 
                &:before {
                    position:absolute; width: 100%; height: 100%; content: ""; left: 0; top:0; 
                    background: url('../images/material/overlay-pop.png') repeat-x 0 0; z-index: 4; 
                }
                img {position: relative; z-index: 2;}
            }
            .detTxt {
                float: left; width: 64.28%; padding: 0 0 0 60px; @include boxSizing(border-box);
                .detYear {
                    position: relative; font-size: 23px; color: $c_333; padding: 0 0 0 53px;  
                    margin: 0 0 28px 0; 
                    &:before {
                        position:absolute; width: 40px; height: 2px; background: $c_blue; top: 50%; left: 0;
                        content: "";  
                        @include transform(translateY(-50%));  
                    }
                }
                .detTitle {  
                    font-size: 49px; font-weight: bold; text-transform: uppercase; font-family: "Oswald"; 
                }
                .detDes {  
                     padding: 45px 0 0 0; position: relative; height: 350px; @include boxSizing(border-box); 
                     font-size: 16px; color: $c_666; line-height: 26px; 
                     .contentScroll {
                        min-height: 320px;
                        max-height: 320px;
                     }
                }
            }       
        }
    }   
    

    .popArrow {  
        position: absolute; top: 50%; width: 28px; height: 22px; cursor: pointer; text-indent: -999999px;
        z-index: 3; opacity: 0.4; 
        @include transform(translateY(-50%));        
        &.prev {
            left: -50px;
            background:url('../images/material/btn-arrow-prev.png') no-repeat 0 0;
        }
        &.next {
            right: -55px; 
            background:url('../images/material/btn-arrow-next.png') no-repeat 0 0;
        }
        &:hover {
            &.prev {
                &.slick-disabled {}
            }
            &.next {}
        }
    }        

}
