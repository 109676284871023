/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : FrontEnd Team  
==============================================================================================*/

/* import bourbon - http://bourbon.io/docs */
@import "bourbon/bourbon";

@mixin transition($attr){
    transition: $attr;
    -webkit-transition: $attr;
    -moz-transition: $attr;
    -ms-transition: $attr;
    -o-transition: $attr;
}
@mixin transform($attr){
    transform: $attr;
    -webkit-transform: $attr;
    -moz-transform: $attr;
    -ms-transform: $attr;
    -o-transform: $attr;
}
@mixin borderRadius($attr){
    border-radius: $attr;
    -webkit-border-radius: $attr;
    -moz-border-radius: $attr;
    -ms-border-radius: $attr;
    -o-border-radius: $attr;
}
@mixin boxShadow($attr){
    box-shadow:  $attr;
    -webkit-box-shadow: $attr;
    -moz-box-shadow: $attr;
    -ms-box-shadow: $attr;
    -o-box-shadow: $attr
}
@mixin textShadow($attr){
    text-shadow:  $attr;
    -webkit-text-shadow: $attr;
    -moz-text-shadow: $attr;
    -ms-text-shadow: $attr;
    -o-text-shadow: $attr
}
@mixin boxSizing($attr){
    box-sizing:  $attr;
    -webkit-box-sizing: $attr;
    -moz-box-sizing: $attr;
    -ms-box-sizing: $attr;
    -o-box-sizing: $attr
}
@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
}
@mixin boxSizing($attr){
    -webkit-box-sizing: $attr;
    -moz-box-sizing: $attr;
    box-sizing: $attr;
}
@mixin clearfix{
    &:before,
    &:after{
        content: " "; 
        display: table; 
    }
    &:after{
        display: block; 
        clear: both;
        height: 0px;
        margin-top: -1px;
        visibility: hidden;
    }
    &{
        *zoom: 1;
    }
}
@mixin transformorigin($attr){
    -webkit-transform-origin: $attr;
    -moz-transform-origin: $attr;
    -o-transform-origin: $attr;
    transform-origin: $attr;
}


/* color global
----------------------------------------------------------------------------------------------*/
$c_111: #111;
$c_161: #161616;
$c_1b1: #1b1b1b;
$c_000: #000;
$c_333: #333;
$c_555: #555;
$c_666: #666;
$c_888: #888; 
$c_999: #999;
$c_909: #909090; 
$c_ea: #eaeaea;
$c_eb: #ebebeb;
$c_e1: #e1e1e1; 
$c_e6e: #e6e6e6; 
$c_efe: #efefef; 
$c_a5a: #a5a3a3;
$c_aea: #aeaeae;
$c_ccc: #ccc;
$c_c9c: #c9c9c9;
$c_ddd: #ddd;
$c_d4d: #d4d4d4;
$c_d7d: #d7d7d7;
$c_d9d: #d9d9d9;
$c_fff: #fff;
$c_f6f: #f6f6f6;
$c_blue: #009fe3;
$c_blue_2: #0da9ec; 
$c_b2b: #b2b2b2;
$c_b5b: #b5b9bc;
$c_d8d: #d8d8d8; 
$c_434: #434343; 
 
